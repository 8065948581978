var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-page-heading',{attrs:{"title":"Authentication","subtitle":"All pages in one spot!"},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('b-breadcrumb',{staticClass:"breadcrumb-alt"},[_c('b-breadcrumb-item',{attrs:{"href":"javascript:void(0)"}},[_vm._v("Authentication")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("All")])],1)]},proxy:true}])}),_c('div',{staticClass:"content"},[_c('h2',{staticClass:"content-heading"},[_vm._v("Original")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('router-link',{attrs:{"to":"/auth/signin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"mb-3"},[_c('i',{staticClass:"fa fa-sign-in-alt fa-2x text-default"})]),_c('p',{staticClass:"text-muted"},[_vm._v("Sign In")])])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('router-link',{attrs:{"to":"/auth/signup"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"mb-3"},[_c('i',{staticClass:"fa fa-user-plus fa-2x text-success"})]),_c('p',{staticClass:"text-muted"},[_vm._v("Sign Up")])])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('router-link',{attrs:{"to":"/auth/lock"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"mb-3"},[_c('i',{staticClass:"fa fa-lock fa-2x text-city"})]),_c('p',{staticClass:"text-muted"},[_vm._v("Lock Screen")])])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('router-link',{attrs:{"to":"/auth/reminder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"mb-3"},[_c('i',{staticClass:"fa fa-life-ring fa-2x text-modern"})]),_c('p',{staticClass:"text-muted"},[_vm._v("Password Reminder")])])])]}}])})],1)]),_c('h2',{staticClass:"content-heading"},[_vm._v("Alternative")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('router-link',{attrs:{"to":"/auth/signin2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"mb-3"},[_c('i',{staticClass:"fa fa-sign-in-alt fa-2x text-default"})]),_c('p',{staticClass:"text-muted"},[_vm._v("Sign In 2")])])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('router-link',{attrs:{"to":"/auth/signup2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"mb-3"},[_c('i',{staticClass:"fa fa-user-plus fa-2x text-success"})]),_c('p',{staticClass:"text-muted"},[_vm._v("Sign Up 2")])])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('router-link',{attrs:{"to":"/auth/lock2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"mb-3"},[_c('i',{staticClass:"fa fa-lock fa-2x text-city"})]),_c('p',{staticClass:"text-muted"},[_vm._v("Lock Screen 2")])])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('router-link',{attrs:{"to":"/auth/reminder2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"mb-3"},[_c('i',{staticClass:"fa fa-life-ring fa-2x text-modern"})]),_c('p',{staticClass:"text-muted"},[_vm._v("Password Reminder 2")])])])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }